/* Example CSS */
.result-summary {
    display: flex;
    justify-content: space-between;
  }
  
  .failed-count, .passed-count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .failed-count {
    color: rgb(248, 39, 39);
  }
  
  .passed-count {
    color: rgb(33, 150, 33);
  }