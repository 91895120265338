.custom-table-passed {
    border-collapse: collapse;
    width: 100%;
  }
  
  .custom-table-passed th, .custom-table-passed td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  }
  
  .custom-table-passed th {
    background-color: #4CAF50; /* Dark Green */
    color: white;
  }
  
  .custom-table-passed td {
    background-color: #e5f9e9; /* Light Green */
    color: #006400; /* Dark Green */
  }