.custom-table-failed {
    border-collapse: collapse;
    width: 100%;
  }
  
  .custom-table-failed th, .custom-table-failed td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  }
  
  .custom-table-failed th {
    background-color: #ff5555; /* Dark Red */
    color: white;
  }
  
  .custom-table-failed td {
    background-color: #ffe5e5; /* Lighter Shade of Red or Pink */
    color: #8b0000; /* Dark Red */
  }