.h6 {
    text-align: center;
}

.table-container {
    display: flex;
    justify-content: center; /* Center the tables horizontally */
  }

.data-table-source,
.data-table-target {
    width: 90%;
    border-collapse: collapse;
    /* Center the table horizontally */
}

.data-table-source th,
.data-table-source td,
.data-table-target th,
.data-table-target td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    /* Center-align text within cells */
}

.data-table-source th {
    background-color: #3180C9;
    color: #fff;
    font-weight: bold;
    /* Bolder text for table headers */
}

.data-table-target th {
    background-color: #E48F5A;
    color: #fff;
    font-weight: bold;
    /* Bolder text for table headers */
}

.data-table-source td {
    background-color: #aec7d7;
    /* Even lighter shade of the header color */
}

.data-table-target td {
    background-color: #f7d0bb;
    /* Lighter shade of the header color */
}

/* Add sorting styles */
.data-table-source th:hover {
    cursor: pointer;
    background-color: #265a8a;
    /* Darken color on hover */
}

.data-table-target th:hover {
    cursor: pointer;
    background-color: #c0744c;
    /* Darken color on hover */
}

/* Alternate row colors for data-table-source */
.data-table-source tr:nth-child(even) td {
    background-color: #d8e4ec;
    /* Even lighter shade for even rows */
}

.data-table-source tr:nth-child(odd) td {
    background-color: #aec7d7;
    /* Alternate shade for odd rows */
}

/* Alternate row colors for data-table-target */
.data-table-target tr:nth-child(even) td {
    background-color: #f8e7d9;
    /* Lighter shade for even rows */
}

.data-table-target tr:nth-child(odd) td {
    background-color: #f3c5b5;
    /* Alternate shade for odd rows */
}