.h6 {
    text-align: center;
}

.inactive-source-table,
.source-info-table {
    border-collapse: collapse;
    width: 100%;
}

.source-info-table th,
.inactive-source-table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.inactive-source-table th {
    background-color: #d69a02;
    color: white;
}

.source-info-table th {
    background-color: #2c00af;
    color: white;
}

.inactive-source-table {
    background-color: #ffe5a2;
    /* Even Lighter Yellow shade */
}

.source-info-table {
    background-color: #bcdffc;
}

/* Style the table cells */
.inactive-source-table td,
.source-info-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}


.red-row .format-cell {
    background-color: rgba(255, 0, 0, 0.2);
    /* Adjust the red background color with opacity */
    font-size: medium;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(255, 176, 176, 0.5);
}

.green-row .format-cell {
    background-color: rgba(0, 255, 0, 0.2);
    /* Adjust the green background color with opacity */
    font-size: medium;
    text-shadow: 1px 1px 1px rgba(187, 249, 191, 0.5);
}